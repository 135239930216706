<template>
  <HelpComponent>
    <b-row class="mb-3">
      <b-col>
        Cet &eacute;cran vous permet de d&eacute;poser vos certificats Bio pour
        la campagne en cours et d'y acc&eacute;der &agrave; nouveau plus tard.
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        Au moment du d&eacute;p&ocirc;t, votre document sera transmis &agrave;
        la personne en charge du suivi des certificats adh&eacute;rents pour
        votre coop&eacute;rative.
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        Vous pouvez utiliser le commentaire pour lui transmettre des
        informations compl&eacute;mentaires (comme les esp&egrave;ces
        certifi&eacute;es).
      </b-col>
    </b-row>
  </HelpComponent>
</template>

<script>
import HelpComponent from "./HelpComponent.vue";
export default {
  name: "HelpCertificatComponent",
  components: { HelpComponent },
};
</script>
