<template>
  <b-card class="mobile-card" header-bg-variant="light">
    <template #header>
      <DateTimeComponent :value="item.dateHeureDepot"></DateTimeComponent>
    </template>
    <b-container>
      <b-row align-v="center">
        <b-col>
          <span class="commentaire">{{ item.commentaire }}</span>
        </b-col>
        <b-col cols="auto">
          <DownloadComponent
            :typeDocument="typeDocument"
            :targetId="item.idCertificat"
          ></DownloadComponent>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import DateTimeComponent from "../DateTimeComponent.vue";
import DownloadComponent from "../controls/DownloadComponent.vue";
export default {
  name: "CertificatCard",
  components: { DateTimeComponent, DownloadComponent },
  props: ["item"],
  data() {
    return {
      typeDocument: this.$store.state.expl.typesDocument.CERTIFICAT,
    };
  },
};
</script>
